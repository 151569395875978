import React, { useState, useEffect } from 'react';
import useSettingsContext from 'contexts/useSettingsContext';
import codesValidator from 'validators/codesValidator';
import useCompetitionEntryContext from 'contexts/useCompetitionEntryContext';
import ValidationErrorCollection from 'validators/ValidationErrorCollection';
import ValidationMessage from 'components/Validation/ValidationMessage';
import ReCaptcha from 'components/Forms/ReCaptcha';
import Form, { createOnChangeHandler } from './Form';
import TextField from 'components/Forms/TextField';
import TextFieldCharRestricted from 'components/Forms/TextFieldCharRestricted';
import SelectField from 'components/Forms/SelectField';

import Link from 'components/Link/Link';
import Button from 'components/Button/Button';

import Modal from 'components/Modal/Modal';
import AlertIcon from 'assets/images/alert.png';

import codeService from 'services/codeService';
import analyticsService from 'services/analyticsService';

import "./CodeEntryForm.scss";

export default (props) => {
  let settingsContext = useSettingsContext();
  let competitionEntryState = useCompetitionEntryContext();

  const [barcode1, setBarcode1] = useState(null);
  const [batchcode1, setBatchcode1] = useState(null);
  const [retailer1, setRetailer1] = useState("");

  let [finalRetailerList, setFinalRetailerList] = useState([""]);

  let [showHasErrorsModal, setShowHasErrorsModal] = useState(false);


  let [currentCodeField, setCurrentCodeField] = useState(1);

  let [validation, setValidation] = useState({
    errors: new ValidationErrorCollection(),
    acknowledged: false
  });

  const retailersList = [
    { "value": 2, "name": "Sainsburys"},
    { "value": 3, "name": "Co-op"},
    { "value": 4, "name": "Asda"},
    { "value": 5, "name": "Morrisons"},
    { "value": 6, "name": "Tesco"},
    { "value": 7, "name": "Spar"},
    { "value": 8, "name": "Budgens"},
    { "value": 9, "name": "Londis"}
  ];

  

  useEffect(() => {
    let result1 = getRetailerName(retailer1);
   

    setFinalRetailerList([result1]);    
    //setFinalRetailerList([parseInt(retailer1), parseInt(retailer2), parseInt(retailer3), parseInt(retailer4), parseInt(retailer5)]);

  }, [retailer1]);  

  function getRetailerName(ret){
    if((ret !== null || ret !== "" || ret != undefined) && (ret > 1 && ret < 10)){
      var res = retailersList.find( v => v.value === parseInt(ret));
      if(res !== undefined && res !== null){        
        return res.name;        
      }
    }
    return "";    
  }



  const createCommand = () => {
    return {
      barcode1,
      batchcode1,
      retailer1,
      currentCodeField: currentCodeField
    }
  }


  const onFormSuccess = () => {

    analyticsService.logEvent("forms", "Forms", {
       action: "ProofOfPurchaseSuccess"
   });

   // history.push("/registration");
    
  }

  const onFormFail = (error) => {
    analyticsService.logEvent("forms", "Forms", {
      action: "ProofOfPurchaseFail",
      label: error
    });
  }

 /* async function handleCodesValidation () {
    var results = await Promise.all([ codeService.validateCodeEntry(barcode1, batchcode1, retailer1)]);

    console.log(results);
    if(results && results[0] && results[0].isValid !== true){
      setShowHasErrorsModal(true);
      return false;
    }
  }*/

  const service = (command) => {

    //handleCodesValidation();
    props.onSubmit(command);
    competitionEntryState.setCodes(command);
    
    //history.push("/registration");
  }

  function onDismissHasErrorsModal () {
    setShowHasErrorsModal(false);
  }

  return (

<>

    <Form 
      id="codes"
      formClass="code-entry"
      autoComplete="off"
      submitTitle="Enter now"
      onSuccess={onFormSuccess}
      onFail={onFormFail}
      command={{
        model: createCommand,
        service: service
      }}
      validation={{
        validator: codesValidator,
        onValidation: setValidation
      }}
      submitAnalytics={[
        { evnt: "form", category: "SubmitForm", action: "SubmitProofOfPurchaseForm"} ,
        { evnt: "form", category: "ProofOfPurchaseForm", action: "RetailerList", label: finalRetailerList} 
      ]}
    > 
      
      <div className="code-entry__inputs">
          <div className="code-entry__codes-wrap" >

            <div className='code-entry__codes'>
              <fieldset className="form__fieldset">
                <TextFieldCharRestricted 
                  id="barcode1" 
                  placeholder="e.g 7622300743659" 
                  label="* Barcode"
                  maxCharLenght ={20}
                  value={barcode1}
                  onChange={createOnChangeHandler(validation, 'barcode1', setBarcode1)}
                  hasErrors={!!validation.errors.barcode1}
                />
                <ValidationMessage errors={validation.errors.barcode1} />               
              </fieldset>
              <fieldset className="form__fieldset">
                <TextFieldCharRestricted 
                  id="batchcode" 
                  placeholder="e.g 0B00B62021" 
                  label="* Batch code"
                  maxCharLenght ={19}
                  onChange={createOnChangeHandler(validation, 'batchcode1', setBatchcode1)}
                  value={batchcode1}
                  hasErrors={!!validation.errors.batchcode1}
                />
                <ValidationMessage errors={validation.errors.batchcode1} />
              </fieldset>

              <fieldset className="form__fieldset"> 
                <SelectField 
                  label="* Please select your retailer"
                  id="retailer1"
                  onChange={createOnChangeHandler(validation, 'retailer1', setRetailer1)}
                  value={retailer1}
                  hasErrors={!!validation.errors.retailer1}
                >
                  <option defaultValue hidden >Select an option</option>
                  {
                    retailersList.map((p, index) => (
                      <option value={p.name} key={"retailer-"+index}>{p.name}</option>
                    ))
                  }
                  <option value="1">Other</option>
                </SelectField>
                <ValidationMessage errors={validation.errors.retailer1} />
              </fieldset>
            
          </div> 

          
            <div className='code-entry__ctas'>
              
                <Link 
                  analytics={{evnt: "ProofOfPurchasePage", category: "FAQsLink", action: "OpenFAQsLink"}}
                  to="faqs" route title="Having difficulty finding your codes?" > 
                  Having difficulty finding your code?
                </Link>
                
            </div>
           
        </div>
          
      </div>
      
      <ReCaptcha captchaSettings={settingsContext.captchaSettings} />
    </Form>
    {/*
        (showHasErrorsModal) &&
        
          <Modal panel
            icon={AlertIcon} 
            onConfirm={onDismissHasErrorsModal}
            title="Not found">
            <p>              
              Hmm, we don't recognise your last entered code! Please try again or check the on-pack offer is still valid.
            </p>
            <Button type="button" title="Try again" onClick={onDismissHasErrorsModal} />
          </Modal>
           
                */}
    </>

  )


}