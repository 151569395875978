import React from 'react';
import './Step.scss';

const Step = (props) => {
  return (
    <article className="step">
      <div className="step__num">
        <span className="step__number">{props.number}</span>
      </div>
      {
        (props.image && props.image_2x) &&
          <img src={props.image} srcSet={props.image_2x + " 2x, " + props.image + " 1x "} alt={props.alt}  className="step__image" />
      }
      <h3 className="step__title type-hpara">{props.title}</h3>
      <p className="step__info" dangerouslySetInnerHTML={{ __html: props.description }}></p>

      {
        props.descriptionLink ??
          <p className="step__info" >
            { props.descriptionLink }
          </p>   
      }
      
    </article>
  )
}

export default Step;
