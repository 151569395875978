import React from 'react';
import classList from 'react-classlist-helper';
import './Prize.scss';

const Prize = (props) => {
  const classMap = {
    "prize": true,
    "prize--selectable": props.selectable,
  };

  const imageClassMap = {
    "prize__image": true,
    "prize__image--contain": props.containImage,
  };

  const onChange = () => {
    if (props.onChange) {
      props.onChange(props.prize);
    }
  }

  return (
    <div className={classList(classMap)}>
      { props.model &&
        <input 
          className="prize__radio" 
          type="radio" 
          id={props.prize.id} 
          name={props.model} 
          value={props.prize.id} 
          checked={props.selected} 
          onChange={onChange} 
        />
      }
      <div className="prize__border"></div>
      <div className={classList(imageClassMap)}>
      <picture>    
        <source media="(min-width: 769px)" srcSet={props.prize.image2x} type="image/png" />   
        <img src={props.prize.image} alt={props.prize.title}  />  
      </picture> 

        { props.model &&
          <div className="prize__fake-radio">
            <span className="prize__tick"></span>
          </div>
        }
      </div>
      <h3 className="prize__title type-h3 type-h3--gold">{props.prize.title}</h3>
      {
        (props.prize.description != null && props.prize.description != "") ??
        <div className="prize__info" dangerouslySetInnerHTML={{__html: props.prize.description}}></div>
      }
      
    </div>
  )
}

export default Prize;
