import ValidationErrorCollection from './ValidationErrorCollection';

const NUM_LETT_REGEX = /^[a-zA-Z0-9_.-]*$/;

function validate(command, settings) {
  let messages = new ValidationErrorCollection();

  if (!command) {
    throw new Error('command cannot be null'); 
  }

  required('barcode1', 'Please enter your barcode');
  required('batchcode1', 'Please enter your batch code');
  required('retailer1', 'Please choose the retailer you purchased from');

  if (command.barcode1 && !NUM_LETT_REGEX.test(command.barcode1)) {
    messages.addError('barcode1', 'Please enter a valid barcode');
  }

  if (command.batchcode1 && !NUM_LETT_REGEX.test(command.batchcode1)) {
    messages.addError('batchcode1', 'Please enter a valid batch code');
  }
      
  return messages;
  
  function required(prop, error) {
    if (!command[prop]) {
      messages.addError(prop, error);
    }
  }
}

export default {
  validate
};
