import React, {useState} from 'react';
import { Redirect, useParams } from 'react-router-dom';
import promotionsService from 'services/promotionsService';
import AttractionSelection from './AttractionSelection';
import useSettingsContext from 'contexts/useSettingsContext';
import useEffectOnce from 'hooks/useEffectOnce';

export default (props) => {
    let settings = useSettingsContext();
    let isCompetitionOpen = settings.competitionStatus.openState === "Open";
    const [promoCode, setPromoCode] = useState("");
    const [promoIsOpen, setPromoIsOpen] = useState(false);
    const [hasRequestedData, setHasRequestedData] = useState(false);

    let useAltLink = false;

    useEffectOnce(()=>{
        async function getPromotionStatus(){
            await promotionsService.getPromotionStatus(props.keyName)
            .then((data) => { 
                setPromoCode(data[0].promoCode); 
                //setPromoIsOpen(true); 
                setPromoIsOpen(data[0].isOpen); 
                setHasRequestedData(true); 
            })
            .catch(err => console.log(err)) ;
            }
        getPromotionStatus();
    });

    if(hasRequestedData){
        if (isCompetitionOpen && promoIsOpen) {
            return (
            <AttractionSelection promotion={props.keyName} promoCode={promoCode} useAltLink={useAltLink} />
            )
        } else {
            return <Redirect to='/' />
        }
    } else {
        return (
            <div>Loading...</div>
        )
    }

}
