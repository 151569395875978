import React from 'react';
import { Link } from 'react-router-dom';

export default () => {
  
    return (
      <section className="layout-general layout-wrapper">
        <div className="layout-container layout-container--wide layout-container--center">
        <h1 className="type-h1">Not Found</h1>
        <p>The page you are looking for could not be found.</p>
        <Link 
          to="/">
          Go To Homepage
        </Link>
        </div>
      </section>
    );
}