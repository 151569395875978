import React from 'react';
import classList from 'react-classlist-helper';

import attractionList from 'utils/attractions';

import Link from 'components/Link/Link';
import './AttractionModalBlock.scss';



export default (props) => {

  const getAttractionLocations = (attr_id) => {
    
    let locations = null;

    locations = props.locations.map((val, index) => (
      
      <Link 
        analytics={{evnt: "AttractionSelection", category: props.promoCode, action: "Open"+val.title.replace(/\s/g, '')}}
        href={ (val.link23 !== undefined  && val.link23.length > 0 ? val.link23 : val.link) + props.promoCode}
        target='_blank'
        lozenge
        title={val.title} 
        key={val.id+"_"+index}>
          { val.location }
      </Link>

    ));

     return locations;
  }

  const classMap = {
    "attraction-block-modal": true,
    "attraction-block-modal--show": props.open
  };
  

  return (
    <>
    <div id={props.id} className={classList(classMap)} key={props.id} >
       <button className='attraction-block' type='button' tabIndex="0" onClick={props.onClick}>
          {
            (props.image && props.image2x) &&
              <img src={props.image} srcSet={props.image2x + " 2x, " + props.image + " 1x "} alt={props.title + " Logo"} />
          }

          <span>{props.title}</span>
        </button>
      
        <div className="modal-background" >
          <div className="modal modal--show modal--panel-content" role="dialog" aria-modal="true" tabIndex="-1" >
            <button className="modal__close" onClick={props.onClickClose}>Close</button>
            <div className='modal__content'>
              <img className="modal__icon" src={props.image} alt={props.title + " Logo"} />
              <p>Choose your location</p>
              {getAttractionLocations(props.attr_id)}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}