import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { Helmet } from "react-helmet";
import useEffectOnce from 'hooks/useEffectOnce';

import classList from 'react-classlist-helper';
import Button from 'components/Button/Button';
import useSettingsContext from 'contexts/useSettingsContext';
import SettingsContext from 'contexts/SettingsContext';
import useCompetitionEntryContext from 'contexts/useCompetitionEntryContext';
import competitionEntryService from 'services/competitionEntryService';
import analyticsService from 'services/analyticsService';
import Banner from 'components/Banner/Banner';
import ModuleBanner from 'assets/images/campaign-2023/banner/banner-headline.png';
import ModuleBanner_2x from 'assets/images/campaign-2023/banner/banner-headline@2x.png';
import ModuleBannerMob from 'assets/images/campaign-2023/banner/banner-headline-mob.png';
import ModuleBannerMob_2x from 'assets/images/campaign-2023/banner/banner-headline-mob@2x.png';
import NpsBanner from 'components/NpsBanner/NpsBanner';
import timer from 'utils/timer';


import './Thankyou.scss';
import Link from 'components/Link/Link';


export default () => {
  let { entryPublicId } = useParams();
  let [settings, setSettings] = React.useContext(SettingsContext);

  let settingsContext = useSettingsContext();

  let compState = settingsContext.competitionStatus.openState;
  let compPhaseId = settingsContext.competitionStatus.currentPhasePublicId;
  let competitionIsOpen = compState === "Open";
  let competitionIsMopup = compPhaseId === "mop-up";

  let competitionEntryContext = useCompetitionEntryContext();
  let [entry, setEntry] = useState(competitionEntryContext.result);

  
  let [showNps, setShowNps] = useState(null);

  //var hasWonTest = true;

  useEffect(() => {
    async function fetchData() {
      let result = await competitionEntryService.getCompetitionEntry(entryPublicId);
      
      setEntry(result);
    }
    
    if (entryPublicId && !entry) {
      fetchData();
    }


    var email = competitionEntryContext.email;
    //Had to go here, as not triggered on last page
    if(email != null)
    {
      // Save email to DataLayer - GTM uses this to pass to Lytics (Only if the user has accepted targeting cookies)
      
       analyticsService.logEvent("forms", "Forms", {
         action: "EmailCapture",
         email
       });

       analyticsService.logEvent("forms", "Forms", {
         action: "RegistrationSuccess"
       });
    }
  
    
  }, [entry]);

  
  useEffectOnce(() => {
    timer.delay(1500).then(() => setShowNps(true));
  });


  if (entryPublicId && !entry) {
    return (
      <div>Loading...</div>
    )
  }

  if (!competitionIsMopup && (!entry.entryPublicId || !entryPublicId)) {
    return (
      <Redirect to="/" />
    )
  }

  const renderNpsBanner = (entryPublicId) => {
    if (!entryPublicId) return null;
    return <NpsBanner onClose={() => setShowNps(false)} competitionEntryPublicId={entryPublicId} show={showNps} />
  }

  /* RENDER */
  return <>  
    <Helmet>
      <meta charSet="utf-8" />
      <title>Thank you | Cadbury</title>
    </Helmet>
    <section className="layout layout-general layout-wrapper layout-wrapper--column ">
    <Banner 
            image={ModuleBanner}
            image2x={ModuleBanner_2x}
            imageMob={ModuleBannerMob}
            imageMob2x={ModuleBannerMob_2x}
            alt={"Theme park ballons"}
            />
      <div className="layout-container layout-container--slim layout-container--center thankyou">
        <h1 className="type-h2 type-h2--gold">You're on your way to your fun day out!</h1>
        
        <p className="thankyou__para">
            Thank you for entering your pack details, please check your inbox to find your unique code and link to book your fun day out!
        </p>
        <Button className="button--wide thankyou__cta" title="Visit Cadbury Website" href="https://www.cadbury.co.uk/" />
        
        <div className='type-disclaimer'>
          <p >
          * 2024 packs: 10-50% off for up to 4 Adult and Child online priced entry tickets. See full <Link route to="/terms-and-conditions">terms and conditions</Link> for more details.
          </p>
          <p >
          ** 2023 packs: *Up to 50% off for up to 4 "On The Day" full priced Adult and Children entry tickets. See full <Link route to="/terms-and-conditions">terms and conditions</Link> for more details.
          </p>
        </div>
      </div>
      
      { renderNpsBanner(entryPublicId) }

    </section>
  </>;
  
  
}
