import React, { useRef, useEffect, useState } from 'react';
import classList from 'react-classlist-helper';
import useEffectOnce from 'hooks/useEffectOnce';
import './TextField.scss';

export default (props) => {
  const [value, setValue] = useState(props.value);
  const inputRef = useRef();
  const classMap = {
    "textfield": true,
    "textfield--error": props.hasErrors,
  };

  useEffect(() => {
    setValue(props.value);
  }, [props.value])

  function onChange(event) {
    setValue(event.target.value);
    if (props.onChange) {
      props.onChange(event.target.value);
    }
  }

  function onBlur(event) {
    setValue(event.target.value);
    if (props.onBlur) {
      props.onBlur(event.target.value);
    }
  }

  useEffectOnce(() => {
    if (props.focus) {
      inputRef.current.focus();
    }
  });

  return (
    <div className={classList(classMap)}>
      <label
        className="textfield__label"
        htmlFor={props.id}>
        {props.label}
      </label>
        <input
          className={"textfield__input " + props.className}
          type={props.type || "text"} 
          id={props.id} 
          name={props.id}
          value={value || undefined}
          ref={inputRef}
          autoComplete={props.autoComplete}
          placeholder={props.placeholder}
          onChange={onChange}
          onBlur={onBlur}
  	      readOnly={props.readonly}
          disabled={props.readonly}
          pattern={props.pattern}
          inputMode={props.inputMode}
          maxLength={props.maxCharLenght}
          
        />
    </div>
  );
}
