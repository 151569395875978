import React from 'react';
import { Helmet } from "react-helmet";
import { useHistory } from 'react-router-dom';

import timer from 'utils/timer';
import useCompetitionEntryContext from 'contexts/useCompetitionEntryContext';
import useSettingsContext from 'contexts/useSettingsContext';
import codeService from 'services/codeService';

import CodeEntryForm from 'containers/Forms/CodeEntryForm';
import Banner from 'components/Banner/Banner';
import Button from 'components/Button/Button';
import Link from 'components/Link/Link';

import Modal from 'components/Modal/Modal';

import AlertIcon from 'assets/images/alert.png';
import BarcodeHelpImage from 'assets/images/campaign-2023/how-2.png';
import BarcodeHelpImage2x from 'assets/images/campaign-2023/how-2@2x.png';

import ModuleBanner from 'assets/images/campaign-2023/banner/banner-headline.png';
import ModuleBanner_2x from 'assets/images/campaign-2023/banner/banner-headline@2x.png';
import ModuleBannerMob from 'assets/images/campaign-2023/banner/banner-headline-mob.png';
import ModuleBannerMob_2x from 'assets/images/campaign-2023/banner/banner-headline-mob@2x.png';
const MAX_ATTEMPTS = 3;

//import './Form.scss';

export default (props) => {
    let [numAttempts, setNumAttempts] = React.useState(1);
  let [status, setStatus] = React.useState('Input');
  let competitionEntryState = useCompetitionEntryContext();
  let settings = useSettingsContext();
  let history = useHistory();

    async function onCodeSubmit(codes) {
        setStatus('Uploading');
        let isValid = await codeService.validateAllCodesEntry(codes);
    
        if (isValid) {
          setStatus('Valid');
          await timer.delay(2000);
          competitionEntryState.setCodes(codes);
          history.push("/registration");

        } else if (numAttempts >= MAX_ATTEMPTS) {
          setNumAttempts(1);
          setStatus('TooManyAttempts');
        } else {
          setNumAttempts(numAttempts + 1);
          setStatus('NotFound');
        }
    }
      
    
    function onDismissModal()
    {
      if(props.onDismiss) {
        props.onDismiss();
      }
      document.body.classList.remove("overflow-hidden-mediumdown");
    }


  /* RENDER */
  return <>  
    <Helmet>
      <meta charSet="utf-8" />
      <title>Enter your code | Cadbury</title>
    </Helmet>
    <section className="layout layout-general layout-wrapper code-entry">
    <Banner 
            image={ModuleBanner}
            image2x={ModuleBanner_2x}
            imageMob={ModuleBannerMob}
            imageMob2x={ModuleBannerMob_2x}
            alt={"Theme park ballons"}
            />
      <div className="layout-container layout-container--medium layout-container--center">
        <h1 className="type-h2 type-h2--gold">Enter your barcode</h1>
        <p className="form__intro form__intro--slim">
            Enter your barcode and batch code (found on the reverse or underside of your pack). Don't forget to keep your receipt.
        </p>  

        <div className='code-entry__img'>
          <img src={BarcodeHelpImage} srcSet={BarcodeHelpImage2x + " 2x, " + BarcodeHelpImage + " 1x "} alt="Form Graphic" />
        </div>
        <CodeEntryForm 
              onSubmit={onCodeSubmit} 
            />
            
      {
        (status  === 'NotFound') &&
            <Modal panel 
              icon={AlertIcon} 
              onConfirm={onDismissModal}
              title="Not found">
              <p>
                Hmm, we don’t recognise your last entered code! Please try again or check the on-pack offer is still valid. 
              </p>
              <Button title="Try again" onClick={() => setStatus('Input')} />
              <Link route title="See our FAQs" to="/faqs" onClick={onDismissModal}>See our FAQs</Link>
            </Modal>
      }
      {
        (status  === 'TooManyAttempts') &&
        
            <Modal panel 
              icon={AlertIcon} 
              onConfirm={onDismissModal}
              title="Too many attempts">
              <p>
                Hmm, we don't recognise that code! Try again, or contact us if there's a problem. 
              </p>
              <Link lozenge route title="See our FAQs" to="/faqs" onClick={onDismissModal}>See our FAQs</Link> 
              <Link title="Contact customer services" to="https://contactus.mdlzapps.com/form?siteId=hyR6CJc3CK6Ii7pdDEiVDC7t6jWVjYW2">Customer services</Link> 
            </Modal>
          
      }
      {
        (status  === 'Uploading') &&
          <Modal loading />
      }
      {
        (status  === 'Valid') &&
          <Modal success />
      }
      </div>

    </section>

    
  </>;
}