import React from 'react';
import classList from 'react-classlist-helper';

import KvBgContent from 'assets/images/campaign-2023/key visuals/kv-header-content-23.png';
import KvBgContent_2x from 'assets/images/campaign-2023/key visuals/kv-header-content-23@2x.png';
import KvBgContentMob from 'assets/images/campaign-2023/key visuals/kv-header-content-mob-23.png';
import KvBgContentMob_2x from 'assets/images/campaign-2023/key visuals/kv-header-content-mob-23@2x.png';

import './KeyVisual.scss';

export default (props) => {

  const classes = {
    "keyvis": true,
    "keyvis--mopup": !props.isMainPhase
  }

  return (
    <div className={classList(classes)}>
      <div className="keyvis__bg"></div>
      {
        KvBgContent &&
        <picture>
          <source media="(min-width: 640px)" srcSet={KvBgContent_2x + " 2x, " + KvBgContent + " 1x "} type="image/png" />
          <img src={KvBgContentMob} srcSet={KvBgContentMob_2x + " 2x, " + KvBgContentMob + " 1x "} alt="Big ticket savings text with themepark balloons" className='keyvis__content-img' />
        </picture>
      }
    </div>
  )
}