import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import SettingsContext from 'contexts/SettingsContext';

import ValidationErrorCollection from 'validators/ValidationErrorCollection';

import Step from 'components/Step/Step';
import Link from 'components/Link/Link';


import KeyVisual from './KeyVisual';

import How1 from 'assets/images/campaign-2023/how-1.jpg';
import How2 from 'assets/images/campaign-2023/how-2.png';
import How3 from 'assets/images/campaign-2023/how-3.png';
import How4 from 'assets/images/campaign-2023/how-4.png';

import How1_2x from 'assets/images/campaign-2023/how-1@2x.jpg';
import How2_2x from 'assets/images/campaign-2023/how-2@2x.png';
import How3_2x from 'assets/images/campaign-2023/how-3@2x.png';
import How4_2x from 'assets/images/campaign-2023/how-4@2x.png';

import './Home.scss';
import SummaryTsandCs from 'components/SummaryTsandCs/SummaryTsandCs';

export default () => {
  const [settings, setSettings] = React.useContext(SettingsContext);
  let compState = settings.competitionStatus.openState;
  let competitionIsOpen = compState === "Open";
  let [validation, setValidation] = useState({
    errors: new ValidationErrorCollection(),
    acknowledged: false
  });


  const renderSteps = (steps) => {
    return steps.map((step, index) => {
      return (
        <Step key={step.number} {...step} />
      )
    })
  }

  const renderEntryCta = () => {
    return  <Link 
    analytics={{evnt: "enterCompetition", category: "ProofOfPurchase", action: "OpenProofOfPurchasePage"}}
    to="/code-entry"
    route lozenge >Enter now</Link>
  }

  const renderIntroMessage = (compState) => {
    switch (compState) {
      case 'Open':
        return <>
          <div className="home__intro">
            <div className="type-h2 type-h2--gold">
              Family fun days are back
            </div>
            <div className="home__intro-body">
              <p>Don’t miss out on big ticket savings to the UK’s top attractions, with promotional packs of Cadbury. Rollercoasters, jousting or coming face-to-fin with majestic sea creatures? </p>
              <p>They're all just a click away.</p>
            </div>
          </div>
          <div className="home__cta">
            {renderEntryCta()}
          </div>
        </>

      case 'NotStarted':
        return <>
          <div className="home__intro">
            <div className="type-h2 type-h2--gold">
              Coming soon!
            </div>
            <div className="home__intro-body">
              <p>Keep your eyes peeled for our promotional packs in stores soon and then come back to claim your free ticket!*.</p>
              <p>In the mean time, keep up to date on the latest Cadbury news and tasty treats!</p>
            </div>
          </div>
          <div className="home__cta">
            <Link to="https://www.cadbury.co.uk" lozenge>Visit Cadbury Website</Link>
          </div>
          <div className="home__disclaimer">
            <p>*Up to 50% off for up to 4 "On The Day" full priced Adult and Children entry tickets. See full <a href='/terms-and-conditions' target='_blank'>terms and conditions</a> for more details.</p>
          </div>
        </>

      case 'Closed':
        return <>
          <div className="home__intro">
            <div className="type-h2 type-h2--gold">
              Ahh sorry!
            </div>
            <div className="home__intro-body">
              <p>The Cadbury ticket promotion has now ended, but please check the Cadbury website to hear about any of our latest news and tasty treats!</p>
            </div>
          </div>
          <div className="home__cta">
            <Link to="https://www.cadbury.co.uk" lozenge>Visit Cadbury Website</Link>
          </div>
        </>
    }
  }

  return (
    <div className="layout">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Family fun days are back | Cadbury</title>
      </Helmet>

      <h1 className="visually-hidden">Cadbury's Dairy Milk - Merlin</h1>

      <section className="layout-wrapper home__banner">
        <div className="home__banner-container">
          <div className="home__banner-content">
            <KeyVisual isMainPhase={competitionIsOpen}>
              {competitionIsOpen && renderEntryCta()}
            </KeyVisual>
          </div>
        </div>

        <div className="home__entrybar" >
          
          {renderIntroMessage(compState)}
        </div>
      </section>


      {(competitionIsOpen) &&
        <section className="layout-wrapper layout-wrapper--drkBackground" id="how-to-play">
          <div className="layout-container layout-container--center ">
            <h2 id="howitworks" className="type-h2 type-h2--gold">How to get my discount</h2>
            <div className="home__steps">
              {
                renderSteps([
                  { number: 1, title: "Pick up a Cadbury promotional pack", image: How1, image_2x: How1_2x, descriptionLink: <><a href="/faqs#1-2">See full product list here</a></>, alt: "Cadbury promotional packs" },
                  { number: 2, title: "Enter your bar/batch code (found on the reverse of pack) & personal details", image: How2, image_2x: How2_2x, descriptionLink: <><a href="/faqs">Having difficulty finding your codes?</a></>, alt: "Form Graphic" },
                  { number: 3, title: "We’ll then send you an email with your unique code and link to claim your discount*", image: How3, image_2x: How3_2x, alt: "Claim your discount text" },
                  { number: 4, title: "Select your attraction, purchase your tickets and then Print / Download these before your fun day out!", image: How4, image_2x: How4_2x, alt: "An Alton Towers balloon" }
                ])
              }
            </div>
            <div className="home__cta home__cta--secondary">
              {renderEntryCta()}
            </div>
            
            <div className="home__disclaimer">
              <p>* 2024 packs: 10-50% off for up to 4 Adult and Child online priced entry tickets. See full&nbsp;
              <Link 
                analytics={{evnt: "HowToDisclaimer", category: "TermsAndConditionsLink", action: "OpenTermsAndConditions"}}
                to="/terms-and-conditions"
                route
                title="Terms and Conditions" >terms and conditions</Link> for more details.</p>
                <p>** 2023 packs: *Up to 50% off for up to 4 "On The Day" full priced Adult and Children entry tickets. See full&nbsp;
              <Link 
                analytics={{evnt: "HowToDisclaimer", category: "TermsAndConditionsLink", action: "OpenTermsAndConditions"}}
                to="/terms-and-conditions"
                route
                title="Terms and Conditions" >terms and conditions</Link> for more details.</p>
            </div>
            
          </div>
        </section>

      }

      {/*
        (competitionIsOpen) &&
        <section className="layout-wrapper">
          <div className="layout-container layout-container--center ">
            <div className="home__entrybar home__entrybar--secondary" >
              <div className="home__intro">
                <div className="type-h2 type-h2--gold">
                  Do you have a promotional pack from 2022?
                </div>
                <div className="home__intro-body">
                  <p>If you have purchased a Cadbury promotional pack from 2022, please click the link below to redeem your offer.</p>
                </div>
              </div>
              <div className="home__cta">
                <Link to="https://www.funcountdown.com/cadbury/" lozenge>Book now</Link>
              </div>
            </div>
          </div>
        </section>*/
      }



      {
        (competitionIsOpen) &&
        <SummaryTsandCs className="layout-wrapper--drkBackground" />
       
      }

    </div>
  );
}