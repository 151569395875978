import api from './api';

function getAttractionsList(promoCode) {
    return api
        .get(`/settings/attractionslist/${promoCode}`);
}

function getPromotionStatus(promoCode) {
    return api
        .get(`/settings/promotionstatus/${promoCode}`);
}

function getPromotionList() {
    return api
        .get(`/settings/promotionslist/`);
}

export default {
    getAttractionsList,
    getPromotionStatus,
    getPromotionList
};