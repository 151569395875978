//@1x
import Attraction1 from 'assets/images/attractions/alton_towers-23.png';
import Attraction2 from 'assets/images/attractions/chessington_wa-23.png';
import Attraction3 from 'assets/images/attractions/legoland_wr-23.png';
import Attraction4 from 'assets/images/attractions/thorpe_park-23.png';
import Attraction5 from 'assets/images/attractions/warwick_castle-23.png';
import Attraction6 from 'assets/images/attractions/sealife-23.png';
import Attraction7 from 'assets/images/attractions/londoneye-23.png';
import Attraction8 from 'assets/images/attractions/the_dungeons-23.png';
import Attraction9 from 'assets/images/attractions/shreks_adventure-23.png';
import Attraction10 from 'assets/images/attractions/madame_tussauds-23.png';
import Attraction11 from 'assets/images/attractions/legoland_dc-23.png';
import Attraction12 from 'assets/images/attractions/blackpool_tower-23.png';
import Attraction13 from 'assets/images/attractions/bear_grylls_adv-23.png';
import Attraction14 from 'assets/images/attractions/cadbury_world.png';

//@2x
import Attraction1_2x from 'assets/images/attractions/alton_towers-23@2x.png';
import Attraction2_2x from 'assets/images/attractions/chessington_wa-23@2x.png';
import Attraction3_2x from 'assets/images/attractions/legoland_wr-23@2x.png';
import Attraction4_2x from 'assets/images/attractions/thorpe_park-23@2x.png';
import Attraction5_2x from 'assets/images/attractions/warwick_castle-23@2x.png';
import Attraction6_2x from 'assets/images/attractions/sealife-23@2x.png';
import Attraction7_2x from 'assets/images/attractions/londoneye-23@2x.png';
import Attraction8_2x from 'assets/images/attractions/the_dungeons-23@2x.png';
import Attraction9_2x from 'assets/images/attractions/shreks_adventure-23@2x.png';
import Attraction10_2x from 'assets/images/attractions/madame_tussauds-23@2x.png';
import Attraction11_2x from 'assets/images/attractions/legoland_dc-23@2x.png';
import Attraction12_2x from 'assets/images/attractions/blackpool_tower-23@2x.png';
import Attraction13_2x from 'assets/images/attractions/bear_grylls_adv-23@2x.png';
import Attraction14_2x from 'assets/images/attractions/cadbury_world@2x.png';


const attractionLogos = [
    { 
        "id" : "alton_towers",
        "image": Attraction1, 
        image2x: Attraction1_2x
    },
    { 
        "id" : "chessington_wa",
        "image": Attraction2, 
        image2x : Attraction2_2x
    },
    { 
        "id" : "legoland_wr",
        "image": Attraction3, 
        image2x : Attraction3_2x
    },
    { 
        "id" : "thorpe_park",
        "image": Attraction4, 
        image2x : Attraction4_2x
    },
    { 
        "id" : "warwick_castle",
        "image": Attraction5, 
        image2x : Attraction5_2x
    },
    { 
        "id" : "sealife",
        "image": Attraction6, 
        image2x : Attraction6_2x
    },
    { 
        "id" : "londoneye",
        "image": Attraction7, 
        image2x : Attraction7_2x
    },
    {
        "id" : "the_dungeons",
        "image": Attraction8, 
        image2x : Attraction8_2x
    },
    { 
        "id" : "shreks_adventure",
        "image": Attraction9, 
        image2x : Attraction9_2x
    },
    { 
        "id" : "madame_tussauds",
        "image": Attraction10, 
        image2x : Attraction10_2x
    },
    { 
        "id" : "legoland_dc",
        "image": Attraction11, 
        image2x : Attraction11_2x
    },
    { 
        "id" : "blackpool_tower",
        "image": Attraction12, 
        image2x : Attraction12_2x
    },
    { 
        "id" : "bear_grylls_adv",
        "image": Attraction13,
        image2x : Attraction13_2x
    },
    { 
        "id" : "cadbury_world",
        "image": Attraction14,
        image2x : Attraction14_2x
    }
]

export default attractionLogos;