import React, { useEffect, useState } from 'react';

import prizeClaimService from 'services/prizeClaimService';
import prizeClaimValidator from 'validators/prizeClaimValidator';

import useSettingsContext from 'contexts/useSettingsContext';
import SettingsContext from 'contexts/SettingsContext';

import Button from 'components/Button/Button';
import TextField from 'components/Forms/TextField';
import ValidationMessage from 'components/Validation/ValidationMessage';
import ValidationSummary from 'components/Validation/ValidationSummary';
import ValidationErrorCollection from 'validators/ValidationErrorCollection';
import ReCaptcha from 'components/Forms/ReCaptcha';
import Modal from 'components/Modal/Modal';
import AlertIcon from 'assets/images/alert.png';
import Link from 'components/Link/Link';
import HotChocImage from 'assets/images/campaign/how-3.png';

import Form, { createOnChangeHandler } from './Form';

import './Claim.scss';
import './Form.scss';

export default (props) => {
  let [settings, setSettings] = React.useContext(SettingsContext);
  let { token } = props;
  let [claim, setClaim] = useState();
  let [sent, setSent] = useState(false);
  let [address1, setAddress1] = useState('');
  let [address2, setAddress2] = useState('');
  let [town, setTown] = useState(null);
  let [county, setCounty] = useState(null);
  let [postcode, setPostcode] = useState(null);
  let [validation, setValidation] = useState({
    errors: new ValidationErrorCollection(),
    acknowledged: false
  });

  
  useEffect(() => {
    let account = settings.account;
    if (!account) return;


    if (account.address1) {
      setAddress1(account.address1);
    }

    if (account.address2)  {
      setAddress2(account.address2);
    }

    if (account.city) {
      setTown(account.city);
    }

    if (account.county) {
      setCounty(account.county);
    }

    if (account.postcode) {
      setPostcode(account.postcode);
    }
    
  }, [settings.account])

  useEffect(() => {
    async function fetchData() {
      let result = await prizeClaimService.getPrizeClaim(token);
      setClaim(result);
    }

    if (token && !claim) {
      fetchData();
    }
  }, [claim, token]);

  if (token && !claim) {
    return (
      <div>Loading...</div>
    )
  }

  const createCommand = () => {
    let command = {
      address1,
      address2,
      town,
      county,
      postcode,
      token
    };

    return command;
  }

  const onFormSuccess = () => {
    setSent(true);
  }

  const onDismissModal = () => {
    setValidation(prevState => ({ ...prevState, acknowledged: true }));
  }

  if (!claim.canClaim) {
    return (
      <section className="layout-general layout-wrapper">
        <div className="layout-container layout-container--slim layout-container--center">
          <h1 className="type-h2 type-h2--gold">Sorry!</h1>
          <p className="form__intro">{claim.error.message}</p>  
        </div>
      </section>
    )
  }

  return (  
    <section className="layout-general layout-wrapper">
      <div className="layout-container layout-container--slim layout-container--center claim">
        { !sent ? 
          (
            <>
            <h1 className="type-h2 type-h2--gold">Congratulations!</h1>
            <p className="form__intro">Please complete your details below for verification purposes, and your e-voucher will be winging its way to you shortly!</p>  

            <Form 
              id="claim"
              submitTitle="Claim"
              onSuccess={onFormSuccess}
              command={{
                model: createCommand,
                service: prizeClaimService.addPrizeClaim
              }}
              validation={{
                validator: prizeClaimValidator,
                onValidation: setValidation
              }}
            >
              
              { validation.errors.hasErrors && validation.acknowledged &&
                <ValidationSummary 
                  errors={validation.errors}
                  handlePropertyErrors={['RecaptchaToken']}
                />
              }

              <fieldset className="form__fieldset">
                <TextField 
                  label="Address line 1"
                  id="address1"
                  onChange={createOnChangeHandler(validation, 'address1', setAddress1)}
                  value={address1}
                  hasErrors={!!validation.errors.address1}
                />
                <ValidationMessage errors={validation.errors.address1} />
              </fieldset>

              <fieldset className="form__fieldset">
                <TextField 
                  label="Address line 2 (optional)"
                  id="address2"
                  onChange={createOnChangeHandler(validation, 'address2', setAddress2)}
                  value={address2}
                  hasErrors={!!validation.errors.address2}
                />
                <ValidationMessage errors={validation.errors.address2} />
              </fieldset>

              <fieldset className="form__fieldset">
                <TextField
                  label="Town/City"
                  id="town"
                  onChange={createOnChangeHandler(validation, 'town', setTown)}
                  value={town}
                  hasErrors={!!validation.errors.town}
                />
                <ValidationMessage errors={validation.errors.town} />
              </fieldset>

              <fieldset className="form__fieldset">
                <TextField 
                  label="County"
                  id="county"
                  onChange={createOnChangeHandler(validation, 'county', setCounty)}
                  value={county}
                  hasErrors={!!validation.errors.county}
                />
                <ValidationMessage errors={validation.errors.county} />
              </fieldset>

              <fieldset className="form__fieldset">
                <TextField 
                  label="Postcode"
                  id="postcode"
                  onChange={createOnChangeHandler(validation, 'postcode', setPostcode)}
                  value={postcode}
                  hasErrors={!!validation.errors.postcode}
                />
                <ValidationMessage errors={validation.errors.postcode} />
              </fieldset>

              <ReCaptcha captchaSettings={settings.captchaSettings} />

              { validation.errors.hasErrors && !validation.acknowledged &&
                <Modal 
                  panel 
                  hideClose
                  icon={AlertIcon}
                  onDismiss={onDismissModal}
                  onConfirm={onDismissModal}
                  title="Oops">
                  <ValidationSummary 
                    modal
                    defaultMessage="Please check you have entered all fields correctly."
                    errors={validation.errors}
                    handlePropertyErrors={['RecaptchaToken']}
                  />
                  { !validation.errors.globalErrors.length ? (
                      <Button title="Try again" onClick={onDismissModal} />
                    ) : (
                      <Button title="Back to homepage" href="/" />
                    )
                  }
                </Modal>
              }
            </Form>
            </>
          ) : (
            <>
              <h1 className="type-h2 type-h2--gold">Thank you!</h1>
              <p className="form__intro">Your prize will be winging its way to you shortly.</p>
              <img className="claim__image" src={HotChocImage} alt="Premier League football club logos" />
              <Link to="https://www.cadburyfc.com" lozenge>Go to CadburyFC.com</Link>
            </>
          )
        }

      </div>
    </section>
  );
}