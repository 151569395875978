import React, {useState} from 'react';
import { Route } from 'react-router-dom';
import { AnimatedSwitch } from 'react-router-transition';

import promotionsService from 'services/promotionsService';

import FAQs from './FAQs/FAQs';
import HomeRouteGuard from './Home/HomeRouteGuard';
import RegistrationRouteGuard from './Forms/RegistrationRouteGuard';
import ClaimRouteGuard from './Forms/ClaimRouteGuard';
import TermsAndConditions from './Policies/TermsAndConditions';
import CookiePolicy from './Policies/Cookies';
import Thankyou from './Thankyou/Thankyou';
import FourOhFour from './Errors/FourOhFour';
import LoginRouteGuard from './Forms/LoginRouteGuard';
import CodeEntry from './Forms/CodeEntry';
import PrizeSelectionRouteGuard from './PrizeSelection/PrizeSelectionRouteGuard';

import AttractionSelectionRouteGuard from './AttractionSelection/AttractionSelectionRouteGuard';
import useEffectOnce from 'hooks/useEffectOnce';

export default (props) => {
  const [promotionsUrlsList, setPromotionsUrlsList] = useState(null);
  const [hasRequestedData, setHasRequestedData] = useState(false);

  useEffectOnce(() => {
    async function getPromotionList() {
      await promotionsService.getPromotionList()
        .then((data) => { 
          setPromotionsUrlsList(data);     
          setHasRequestedData(true);
        })
        .catch(err => console.log(err)) ;
    }
    getPromotionList();
  });

  return (
    <AnimatedSwitch
      atEnter={{ opacity: 0 }}
      atLeave={{ opacity: 0 }}
      atActive={{ opacity: 1 }}
      className="switch-wrapper"
    >
      <Route 
        exact 
        key="home"
        path="/">
        <HomeRouteGuard />
      </Route>
      <Route 
        exact 
        key="login"
        path="/login">
        <LoginRouteGuard />
      </Route>
      <Route 
        exact 
        key="prize-select"
        path="/prize-select">
        <PrizeSelectionRouteGuard />
      </Route>
      <Route 
        exact 
        key="code-entry"
        path="/code-entry">
        <CodeEntry />
      </Route>
      <Route 
        exact 
        key="registration"
        path="/registration">
        <RegistrationRouteGuard />
      </Route>
      <Route 
        exact 
        key="thankyou"
        path="/thankyou/:entryPublicId">
        <Thankyou />
  </Route>
      <Route 
        exact 
        key="thankyou"
        path="/thankyou">
        <Thankyou />
      </Route>
      <Route 
        exact 
        key="faqs"
        path="/faqs">
      <FAQs />
      </Route>
      <Route 
        exact 
        key="terms-and-conditions"
        path="/terms-and-conditions">
        <TermsAndConditions />
      </Route>
      <Route 
        exact 
        key="cookie-policy"
        path="/cookie-policy">
        <CookiePolicy />
      </Route>
      <Route 
        exact 
        key="claim"
        path="/claim/:token">
        <ClaimRouteGuard />
      </Route>

      {
        promotionsUrlsList&&
        promotionsUrlsList.map((promotion, index) => {
          return (
            <Route 
              exact 
              key={promotion}
              path={"/"+promotion}> 
              <AttractionSelectionRouteGuard keyName={promotion} />
            </Route>
          )
        })
      }
      {
        hasRequestedData &&
        <Route>
          <FourOhFour />
        </Route>
      }
      
    </AnimatedSwitch> 
  );
}
