import React, { useRef, useEffect } from 'react';
import classList from 'react-classlist-helper';
import useEffectOnce from 'hooks/useEffectOnce';
import './TextField.scss';

import analyticsService from 'services/analyticsService';

export default (props) => {
  const inputRef = useRef();
  const classMap = {
    "textfield": true,
    "textfield--error": props.hasErrors,
  };

  function onChange(event) {
    if (props.onChange) {

      if(props.selectAnalytics){
        var index = event.nativeEvent.target.selectedIndex;
        var analytics = props.selectAnalytics;

        //console.log(analytics.evnt, analytics.category,analytics.action,event.nativeEvent.target[index].text);
        analyticsService.logEvent(analytics.evnt, analytics.category, {
          action: analytics.action,
          label: event.nativeEvent.target[index].text
        });
        props.onChange(event.target.value);

      } else {
        props.onChange(event.target.value);
      }
    }
  }

  function onBlur(event) {
    if (props.onBlur) {
      props.onBlur(event.target.value);
    }
  }

  useEffectOnce(() => {
    if (props.focus) {
      inputRef.current.focus();
    }
  });

  return (
    <div className={classList(classMap)}>
      <label
        className="textfield__label"
        htmlFor={props.id}>
        {props.label}
      </label>
      <select
        className={"textfield__input textfield__input--select " + props.className}
        type="text" 
        id={props.id} 
        name={props.id}
        ref={inputRef}
        placeholder={props.placeholder}
        onChange={onChange}
        onBlur={onBlur}
        readOnly={props.readonly}
        disabled={props.readonly}
      >
        {props.children}
      </select>
    </div>
  );
}
