import React from 'react';
import './Banner.scss';


export default (props) => {
  
    return (
  
      <div className="banner">

        <picture>    
            <source media="(min-width: 640px)" srcSet={props.image2x + " 2x, " + props.image + " 1x "} type="image/png" />
            <img src={props.imageMob} srcSet={props.imageMob2x + " 2x, " + props.imageMob + " 1x "} alt={props.alt} className='banner__content-img' />  
        </picture> 

      </div>
    );
  
}