import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import { useHistory } from 'react-router-dom';

import useEffectOnce from 'hooks/useEffectOnce';
import useSettingsContext from 'contexts/useSettingsContext';
import SettingsContext from 'contexts/SettingsContext';
import useCompetitionEntryContext from 'contexts/useCompetitionEntryContext';
import competitionEntryService from 'services/competitionEntryService';
import competitionEntryValidator from 'validators/competitionEntryValidator';

import Button from 'components/Button/Button';
import Checkbox from 'components/Forms/Checkbox';
import TextField from 'components/Forms/TextField';

import DateInput from 'components/Forms/DateInput';
import Link from 'components/Link/Link';

import ValidationMessage from 'components/Validation/ValidationMessage';
import ValidationSummary from 'components/Validation/ValidationSummary';
import ValidationErrorCollection from 'validators/ValidationErrorCollection';
import ReCaptcha from 'components/Forms/ReCaptcha';
import Modal from 'components/Modal/Modal';
import LoginForm from 'containers/Forms/LoginForm';
import AlertIcon from 'assets/images/alert.png';
import Form, { createOnChangeHandler } from './Form';
import SelectField from 'components/Forms/SelectField';

import Banner from 'components/Banner/Banner';
import ModuleBanner from 'assets/images/campaign-2023/banner/banner-headline.png';
import ModuleBanner_2x from 'assets/images/campaign-2023/banner/banner-headline@2x.png';
import ModuleBannerMob from 'assets/images/campaign-2023/banner/banner-headline-mob.png';
import ModuleBannerMob_2x from 'assets/images/campaign-2023/banner/banner-headline-mob@2x.png';

import analyticsService from 'services/analyticsService';
import reCaptchaService from 'services/reCaptchaService';

import './Form.scss';
import SummaryTsandCs from 'components/SummaryTsandCs/SummaryTsandCs';

export default () => {
  let history = useHistory();
  let competitionEntryContext = useCompetitionEntryContext();
  let settingsContext = useSettingsContext();
  let compState = settingsContext.competitionStatus.openState;
  let compPhaseId = settingsContext.competitionStatus.currentPhasePublicId;
  let competitionIsOpen = compState === "Open";
  let competitionIsMopup = compPhaseId === "mop-up";
  let minBirthDate = new Date();
  minBirthDate.setYear(minBirthDate.getFullYear() - 18);

  let [settings, setSettings] = React.useContext(SettingsContext);
  let [firstName, setFirstName] = useState('');
  let [lastName, setLastName] = useState('');
  let [country, setCountry] = useState('');
  let [email, setEmail] = useState('');
  let [emailConfirm, setEmailConfirm] = useState('');
  let [terms, setTerms] = useState(false);
  let [dob, setDoB] = useState({
    isValid: false
  });
  let [optIn, setOptIn] = useState(false);
  let [teamOptIn, setTeamOptIn] = useState(false);
  let [legalAge, setLegalAge] = useState(false);
  let [oreoEmail, setOreoEmail] = useState(false);
  let [oreoThirdPartyConsent, setOreoThirdPartyConsent] = useState(false);
  let [barnyEmail, setBarnyEmail] = useState(false);
  let [barnyThirdPartyConsent, setBarnyThirdPartyConsent] = useState(false);

  let [validation, setValidation] = useState({
    errors: new ValidationErrorCollection(),
    acknowledged: false
  });

  let [recaptcha2, setRecaptcha2] = useState(null);

  const [firstNameReadOnly, setFirstNameReadOnly] = useState(false);
  const [lastNameReadOnly, setLastNameReadOnly] = useState(false);
  const [emailReadOnly, setEmailReadOnly] = useState(false);
  const [dobReadOnly, setDobReadOnly] = useState(false);
  const [teamCodeReadOnly, setTeamCodeReadOnly] = useState(false);


  let [showValidationModal, setShowValidationModal] = useState(false);

  useEffect(() => {
    let account = settings.account;
    if (!account) return;


    if (account.firstname) {
      setFirstName(account.firstname);
      setFirstNameReadOnly(true);
    }

    if (account.lastname) {
      setLastName(account.lastname);
      setLastNameReadOnly(true);
    }

    if (account.email) {
      setEmail(account.email);
      setEmailConfirm(account.email);
      setEmailReadOnly(true);
    }

    if (account.birthday) {
      setDoB({
        date: new Date(account.birthday),
        isValid: true
      });
      setDobReadOnly(true);
    }

  }, [settings.account])


  useEffect(()=>{
    
    if(validation.errors.hasErrors && !validation.acknowledged){
      setShowValidationModal(true);
    } else {
      setShowValidationModal(false);
    }
    
  }, [validation])


  useEffect(()=>{
    
    var sett_account = settings.account;
    if(sett_account === null || sett_account === undefined){
      setDoB(null);
    } else {

      if (!sett_account.birthday) {
        setDoB(null);
      }
    }
   
  }, [optIn])


  const createCommand = () => {


    let model = {
      firstName,
      lastName,
      email,
      emailConfirm,
      country,
      dob,
      terms,
      isMopupPhase: competitionIsMopup,
      barcode1: competitionEntryContext.barcode1,
      batchcode1: competitionEntryContext.batchcode1,
      retailerid1: competitionEntryContext.retailer1,        
      codeList: competitionEntryContext.codeList,
      optIn,
      teamOptIn,
      oreoEmail,
      oreoThirdPartyConsent,
      barnyEmail,
      barnyThirdPartyConsent,
      recaptcha2,
      legalAge
    };

    if (dob && dob.isValid) {
      Object.assign(model, {
        dateOfBirthDay: dob.date.getDate(),
        dateOfBirthMonth: dob.date.getMonth() + 1,
        dateOfBirthYear: dob.date.getFullYear(),
        dob
      })
    }

    return model;
  }

  var handleReCaptchaV2 = (val) => {
    if(val !== null && val !== ""){
      createOnChangeHandler(validation, 'recaptcha2', setRecaptcha2, val)
    }
  }

  var handleReCaptchaV2Expired = () => {
    setRecaptcha2(null);
  }

  useEffectOnce(() => {   
    window.setTimeout(function(){
      try 
      {
        let $grecaptchav2 = document.querySelector('.g-recaptcha-v2');
        if ($grecaptchav2) {
          
            window.grecaptcha.ready(() => {
                window.grecaptcha.render($grecaptchav2, {
              'sitekey' : '6LcHG-0kAAAAAH7FvO1I0GBhd0Y2jNiehzbPNf-x',
              'callback': handleReCaptchaV2,
              'expired-callback': handleReCaptchaV2Expired
                });
          });
        }
      }catch{}
  }, 1000);
    
  });

  const onFormSuccess = (result) => {

    // forward to thank-you page
    competitionEntryContext.reset();
    competitionEntryContext.setEmail(email);
    competitionEntryContext.setResult(result);

    // Update csfr token and flag as logged in
    setSettings({ ...settings, country: result.country });

    let urlData = !competitionIsMopup ? `/${result.entryPublicId}` : '';

    history.push(`/thankyou${urlData}`);
  }

  const onDismissModal = () => {
    setValidation(prevState => ({ ...prevState, acknowledged: true }));
    /* This has to be here to bypass hideClose */
    document.body.classList.remove("overflow-hidden-mediumdown"); 
  }


  /* RENDER */
  return <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Register | Cadbury</title>
    </Helmet>
    <section className="layout layout-general layout-wrapper layout-wrapper--column">
    <Banner 
            image={ModuleBanner}
            image2x={ModuleBanner_2x}
            imageMob={ModuleBannerMob}
            imageMob2x={ModuleBannerMob_2x}
            alt={"Theme park ballons"}
            />
      <div className="layout-container layout-container--slim layout-container--center layout-container--bottom-space">
        <h1 className="type-h2 type-h2--gold">Your Details</h1>

        <p className="form__intro">
          Please enter your details below to claim your unique code for your discount.
        </p>


        <Form
          id="registration"
          submitTitle="Submit"
          onSuccess={onFormSuccess}
          formClass="form form--slim"
          command={{
            model: createCommand,
            service: competitionEntryService.addCompetitionEntry
          }}
          validation={{
            validator: competitionEntryValidator,
            onValidation: setValidation
          }}
          policyContent={<>
            <p>* 2024 packs: 10-50% off for up to 4 Adult and Child online priced entry tickets. See full <a href='/terms-and-conditions' target='_blank'>terms and conditions</a> for more details.</p>
            <p>** 2023 packs: *Up to 50% off for up to 4 "On The Day" full priced Adult and Children entry tickets. See full <a href='/terms-and-conditions' target='_blank'>terms and conditions</a> for more details.</p>
          </>}
          submitAnalytics={[
            { evnt: "form", category: "RegistrationForm", action: "SubmitForm"},
            { evnt: "form", category: "RegistrationForm", action: "EmailCapture", label: email} ,
            (dob && dob.isValid) &&
            { evnt: "form", category: "RegistrationForm", action: "DateOfBirth", label: dob.date} 
          ]}
        >

          {validation.errors.hasErrors && validation.acknowledged &&
            <ValidationSummary
              errors={validation.errors}
              handlePropertyErrors={['entryData', 'RecaptchaToken', 'optIn']}
            />
          }

          <fieldset className="form__fieldset">
            <TextField
              label="* First name"
              id="firstName"
              onChange={createOnChangeHandler(validation, 'firstName', setFirstName)}
              value={firstName}
              hasErrors={!!validation.errors.firstName}
              readonly={firstNameReadOnly}
            />
            <ValidationMessage errors={validation.errors.firstName} />
          </fieldset>

          <fieldset className="form__fieldset">
            <TextField
              label="* Last name"
              id="lastName"
              onChange={createOnChangeHandler(validation, 'lastName', setLastName)}
              value={lastName}
              hasErrors={!!validation.errors.lastName}
              readonly={lastNameReadOnly}
            />
            <ValidationMessage errors={validation.errors.lastName} />
          </fieldset>
          <fieldset className="form__fieldset">
            <SelectField
              label="* Country"
              id="country"
              onChange={createOnChangeHandler(validation, 'country', setCountry)}
              value={country}
              hasErrors={!!validation.errors.country}
              selectAnalytics={{evnt: "form", category: "RegistrationForm", action: "SelectCountry"}}
            >
              <option defaultValue hidden >Select an option</option>
              <option value="GB">United Kingdom</option>
              <option value="ROI">Republic of Ireland</option>
            </SelectField>
            <ValidationMessage errors={validation.errors.country} />
          </fieldset>
          <fieldset className="form__fieldset">
            <TextField
              label="* Email"
              id="email"
              type="email"
              onChange={createOnChangeHandler(validation, 'email', setEmail)}
              value={email}
              hasErrors={!!validation.errors.email}
              readonly={emailReadOnly}
            />
            <ValidationMessage errors={validation.errors.email} />
          </fieldset>

          <fieldset className="form__fieldset">
            <TextField
              label="Email confirmation"
              id="emailConfirm"
              autoComplete="off"
              type="email"
              onChange={createOnChangeHandler(validation, 'emailConfirm', setEmailConfirm)}
              value={emailConfirm}
              hasErrors={!!validation.errors.emailConfirm}
              readonly={emailReadOnly}
            />
            <ValidationMessage errors={validation.errors.emailConfirm} />
          </fieldset>

          <fieldset className='form__fieldset'>
            <Checkbox 
                id="legalAge" 
                label="* I confirm I am over 18 years old."
                hasErrors={validation.errors.hasErrors && !legalAge}
                onChange={createOnChangeHandler(validation, 'legalAge', setLegalAge)} 
                checkboxAnalytics={{evnt: "form", category: "RegistrationForm", action: "CheckLegalAge"}}
              />
              <ValidationMessage errors={validation.errors.legalAge} />            
          
            <Checkbox
              id="optIn"
              label="I want to receive emails packed with the latest news, competitions & offers from Cadbury. You can <a href='https://contactus.mdlzapps.com/form?siteId=hyR6CJc3CK6Ii7pdDEiVDC7t6jWVjYW2' target='_blank' rel='noopener noreferrer'>unsubscribe here</a> at any time."
              onChange={setOptIn}
              checkboxAnalytics={{evnt: "form", category: "RegistrationForm", action: "CheckOptIn"}}
            />
            {
              optIn &&
              <>
                <br />
                <div className='form__date-entry'>
                  <label
                    className="textfield__label"
                    htmlFor="">
                    If you would like a birthday offer in your inbox, tell us your date of birth (optional). We also use this information to personalise your future experiences with Cadbury.
                  </label>
                  <br />

                  <DateInput
                    className="date-select"
                    minDate={minBirthDate}
                    minDateErrorMessage="You must be over 18 to enter this promotion"
                    onChange={createOnChangeHandler(validation, 'dob', setDoB)}
                    value={dob}
                    readonly={dobReadOnly}
                  />
                  <ValidationMessage errors={validation.errors.dateOfBirthDay || validation.errors.dob} />
                </div>
              </>
            }
          </fieldset>

          <fieldset className="form__fieldset">
            <Checkbox
              id="teamOptIn"
              label="Tick this box to grant Cadbury permission to use your personal information to help us ensure our future communications with you, either through our partners or across third party platforms, are more relevant to you. Cadbury is an affiliate brand of Mondelez International."
              onChange={setTeamOptIn}
              checkboxAnalytics={{evnt: "form", category: "RegistrationForm", action: "CheckThirdPartyOptIn"}}
            />
            <Checkbox
              id="terms"
              label="* I've read & agree to the Cadbury and Merlin ticket promotion &nbsp;<a href='/terms-and-conditions' target='_blank' rel='noopener noreferrer'>Terms and Conditions</a> and &nbsp;<a href='https://eu.mondelezinternational.com/privacy-notice?sc_lang=en&siteId=hyR6CJc3CK6Ii7pdDEiVDC7t6jWVjYW2' rel='noreferrer noopener' target='_blank'>Privacy Policy</a>."
              hasErrors={validation.errors.hasErrors && !terms}
              onChange={createOnChangeHandler(validation, 'terms', setTerms)}
              checkboxAnalytics={{evnt: "form", category: "RegistrationForm", action: "CheckTandCs"}}
            />
            <ValidationMessage errors={validation.errors.terms} />
          </fieldset>

          <ReCaptcha captchaSettings={settingsContext.captchaSettings} />    
          <div class="g-recaptcha-v2" data-sitekey="6LcHG-0kAAAAAH7FvO1I0GBhd0Y2jNiehzbPNf-x"
          data-load={createOnChangeHandler(validation, 'recaptcha2', setRecaptcha2)}></div>
          <ValidationMessage errors={validation.errors.recaptcha2} /> 


          { showValidationModal &&
            <Modal
              panel
              hideClose
              show={showValidationModal}
              icon={AlertIcon}
              alt="Warning sign"
              onDismiss={onDismissModal}
              onConfirm={onDismissModal}
              title={!validation.errors.globalErrors.length ? "Oops" : "ah nuts!"}>
              <ValidationSummary
                modal
                defaultMessage="Please check you have entered all fields correctly."
                errors={validation.errors}
                handlePropertyErrors={['entryData', 'RecaptchaToken', 'optIn']}
              />
              {!validation.errors.globalErrors.length ? (
                <Button 
                  title="Try again" 
                  onClick={onDismissModal} 
                  analytics={{evnt: "RegistrationForm", category: "ValidationModalOptions", action: "TryAgainButton"}}
                  />
              ) : (
                <>
                  <Button title="Back to homepage" 
                    href="/"
                    analytics={{evnt: "RegistrationForm", category: "ValidationModalOptions", action: "BackToHomeButton"}} />
                  <Link 
                    analytics={{evnt: "RegistrationForm", category: "ValidationModalOptions", action: "OpenTermsAndConditions"}}
                    to="/terms-and-conditions"
                    route
                    lozenge
                    title="Terms and Conditions" >Terms & Conditions</Link>
                </>
              )
              }
            </Modal>
          }
        </Form>

      </div>


    </section>
    
    <SummaryTsandCs layoutContainerClass="layout-container--bottom-space" />
  </>;
}