import React from 'react';
import classList from 'react-classlist-helper';
import './Button.scss';
import analyticsService from 'services/analyticsService';

export default (props) => {
  const classMap = {
    "button": true,
    "button--wide": !!props.wide,
    [props.className]: !!props.className,
  };

  const handleOnClick = () => {

    if(props.analytics){
      let analytics = props.analytics;
      let route = (props.href ? props.href : (props.to ? props.to : ""));
      
      //console.log(analytics.evnt, analytics.category, analytics.action, route);
      analyticsService.logEvent(analytics.evnt, analytics.category, {
        action: analytics.action,
        label: route
      });
    }

    if(props.onClick){
      props.onClick();
    }
  }

  if (props.href) {
    return (
      <a 
        className={classList(classMap)}
        href={props.href} 
        title={props.title}
        onClick={handleOnClick}
      >
        {props.title}
      </a>
    )
  } else {
    return (
  
      <button 
        type={props.type ||"button"}
        className={classList(classMap)}
        disabled={props.disabled}
        onClick={handleOnClick}>

          {
            props.buttonMinus &&
            <span className='button__minus-icon'></span>
          }

          {props.title}

          {
            props.buttonPlus &&
            <span className='button__plus-icon'></span>
          }
      </button>
    );
  }
}