import PrizeImage1 from 'assets/images/themes/cadburyfc/badges/club-arsenal.png';
import PrizeImage2 from 'assets/images/themes/cadburyfc/badges/club-av.png';
import PrizeImage3 from 'assets/images/themes/cadburyfc/badges/club-chelsea.png';
import PrizeImage4 from 'assets/images/themes/cadburyfc/badges/club-leeds.png';
import PrizeImage5 from 'assets/images/themes/cadburyfc/badges/club-liverpool.png';
import PrizeImage6 from 'assets/images/themes/cadburyfc/badges/club-manutd-2.png';
import PrizeImage7 from 'assets/images/themes/cadburyfc/badges/club-mancity.png';
import PrizeImage8 from 'assets/images/themes/cadburyfc/badges/club-th--white.png';

import PrizeImage1x2 from 'assets/images/themes/cadburyfc/badges/club-arsenal2x.png';
import PrizeImage2x2 from 'assets/images/themes/cadburyfc/badges/club-av2x.png';
import PrizeImage3x2 from 'assets/images/themes/cadburyfc/badges/club-chelsea2x.png';
import PrizeImage4x2 from 'assets/images/themes/cadburyfc/badges/club-leeds2x.png';
import PrizeImage5x2 from 'assets/images/themes/cadburyfc/badges/club-liverpool2x.png';
import PrizeImage6x2 from 'assets/images/themes/cadburyfc/badges/club-manutd2x.png';
import PrizeImage7x2 from 'assets/images/themes/cadburyfc/badges/club-mancity2x.png';
import PrizeImage8x2 from 'assets/images/themes/cadburyfc/badges/club-th2x--white.png';

const prizeList = [
  { "id": "ars-exp", "image": PrizeImage1, "image2x": PrizeImage1x2, "type": "vertical", "title": "Arsenal FC" },
  { "id": "avl-exp", "image": PrizeImage2, "image2x": PrizeImage2x2, "type": "vertical", "title": "Aston Villa Women FC"},
  { "id": "che-exp", "image": PrizeImage3, "image2x": PrizeImage3x2, "type": "vertical", "title": "Chelsea FC" },
  { "id": "lee-exp", "image": PrizeImage4, "image2x": PrizeImage4x2, "type": "vertical", "title": "Leeds United FC" },
  { "id": "liv-exp", "image": PrizeImage5, "image2x": PrizeImage5x2, "type": "vertical", "title": "Liverpool FC" },
  { "id": "mci-exp", "image": PrizeImage7, "image2x": PrizeImage7x2, "type": "vertical", "title": "Manchester City" },
  { "id": "mun-exp", "image": PrizeImage6, "image2x": PrizeImage6x2, "type": "vertical", "title": "Manchester United" },
  { "id": "tot-exp", "image": PrizeImage8, "image2x": PrizeImage8x2, "type": "vertical", "title": "Tottenham Hotspur" }
]

export default prizeList;