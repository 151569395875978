import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import { useHistory } from 'react-router-dom';

import useCompetitionEntryState from 'contexts/useCompetitionEntryContext';
import ActionBar from 'containers/ActionBar/ActionBar';
import useEffectOnce from 'hooks/useEffectOnce';
import Button from 'components/Button/Button';
import prizeList from 'utils/prizes';
import Prize from 'components/Prize/Prize';

import './PrizeSelection.scss';

export default () => {
  let competitionEntryState = useCompetitionEntryState();
  let history = useHistory();
  let [prize, setPrize] = useState(competitionEntryState.prize);

  useEffectOnce(() => {
    window.scrollTo(0, 0);
    document.body.classList.add('prize-select-page');
  });

  const onContinue = () => {
    if (prize !== null) {
      competitionEntryState.setPrize(prize);
      document.body.classList.remove('prize-select-page');
      history.push("/registration");
    }
  }

  /* RENDER */
  return <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Prize Selection | Cadbury</title>
    </Helmet>
    <div className="layout">
      <ActionBar show={prize !== null}>
        <Button wide title="Continue" onClick={onContinue} />
      </ActionBar> 
      <section className="layout-general layout-wrapper">
        <div className="layout-container layout-container--center prize-select">
          <h1 className="type-h2 type-h2--gold">Choose your prize draw</h1>
          <p>
            Select which one of the prize draws you wish to enter based on the club you support. The prize draws will all take place at the end of the promotional period.
          </p>
          <form>
            <div className="prize-select__list">
              {
                prizeList.map((p) => (
                  <Prize 
                    key={p.id}
                    prize={p} 
                    model="prize"
                    selected={prize && prize.id === p.id} 
                    selectable 
                    onChange={(selected) => setPrize(selected)}
                  />
                ))
              }
            </div>
          </form>

        </div>
      </section>
    </div>
  </>;
}