import React from 'react';
import classList from 'react-classlist-helper';
import './Checkbox.scss';

import analyticsService from 'services/analyticsService';

export default (props) => {

  const classMap = {
    "checkbox": true,
    "checkbox--error": props.hasErrors,
  };


  function onChange(event) {
    if (props.onChange) {

      if(props.checkboxAnalytics && event.target.checked === true){
        var checkTitle = event.nativeEvent.target.title;
        var analytics = props.checkboxAnalytics;

        //console.log(analytics.evnt, analytics.category,analytics.action,checkTitle);
        analyticsService.logEvent(analytics.evnt, analytics.category, {
          action: analytics.action,
          label: checkTitle
        });
        props.onChange(event.target.checked);

      } else {
        props.onChange(event.target.checked);
      }
    }
  }

  return (
    <div className={classList(classMap)}>
      <input 
        className="checkbox__input"
        type="checkbox" 
        id={props.id} 
        name={props.id}
        onChange={onChange}
      />
      <div className="checkbox__fake">
        <span className="checkbox__focus"></span>
      </div>
      <label className="checkbox__label" htmlFor={props.id} dangerouslySetInnerHTML={{ __html: props.label}}></label>
    </div>
  );
}