import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import TextFieldCharRestricted from './TextFieldCharRestricted';

import './DateInput.scss';

export default (props) => {

  const [day, setDay] = useState(null);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);
  
  const [readOnly, setReadOnly] = useState(false);
  

  const isValidDate = (year, month, day) => {
    month = month-1;
    var d = new Date(year, month, day);
    if (d.getFullYear() == year && d.getMonth() == month && d.getDate() == day) {
        return true;
    }
    return false;
  }

  const getDay = (date) => {
    if(date.length > 0){
      var newDate = new Date(date).getDate();
      var returnDay = (newDate.toString().length < 2 ? "0"+newDate : newDate);
      return returnDay;
    } else {
      return "";
    }
    
  }

  const getMonth = (date) => {
    if(date.length > 0){
      
      var newDate = new Date(date).getMonth();
      var returnMonth = (newDate.toString().length < 2 && newDate != 9 ? "0"+(newDate+1) : (newDate+1));
      return returnMonth;
    } else {
      return "";
    }
    
  }

  const getYear = (date) => {
    if(date.length > 0){
      var newDate = new Date(date).getFullYear();
      return newDate;
    } else {
      return "";
    }
    
  }

  useEffect(() => {
    if(props.value != null && props.value.length > 0)
    {
      
        setDay(getDay(props.value));   
        setMonth(getMonth(props.value));  
        setYear(getYear(props.value));  
    }

    setReadOnly(props.readonly);



  }, [props.value, props.readonly]);

  useEffect(() => {

    
    const parseDayAndMonth = val => {
      if (!val) return val;
      return val.length === 1 ? `0${val}` : val
    }


    let inputDate = new Date(`${year}-${parseDayAndMonth(month)}-${parseDayAndMonth(day)}T00:00:01Z`);



    let response = {};
    if (day && month && year && ((year + "").length !== 4) && inputDate) {
    
      response = {
        isValid: false,
        error: "Please enter a valid date"
      };
    } else if (!isValidDate(year, month, day)) {

      response = {
        isValid: false,
        error: "Please enter a valid date"
      };
    } else if (day && month && year && inputDate) {

      if (inputDate == "Invalid Date") {
        response = {
          isValid: false,
          error: "Please enter a valid date"
        };
      } else {
        if (props.minDate && inputDate >= props.minDate) {
          response = {
            isValid: false,
            error: props.minDateErrorMessage || "Min Date"
          };
        } else {
          response = {
            isValid: true,
            date: inputDate
          };
        }
      }
    } else {
      response = {
        isValid: false
      };
    }


    props.onChange(response);

  }, [day, month, year]);

  return <>

    <div className="date-input">
      <TextFieldCharRestricted 
        label="Day"
        id="day"
        autoComplete="bday-day"
        pattern="[0-9]*"
        inputmode="numeric"
        type="number"
        onChange={setDay}
        placeholder="DD"
        value={day}
	      readonly={readOnly}
        maxCharLenght ={2}
        //disabled={readOnly}
      />
      <TextFieldCharRestricted 
        label="Month"
        id="month"
        autoComplete="bday-month"
        pattern="[0-9]*"
        inputmode="numeric"
        type="number"
        onChange={setMonth}
        placeholder="MM"
        value={month}
	      readonly={readOnly}
        maxCharLenght ={2}
      //  disabled={readOnly}
      />
      <TextFieldCharRestricted 
        label="Year"
        id="year"
        autoComplete="bday-year"
        pattern="[0-9]*"
        inputmode="numeric"
        type="number"
        onChange={setYear}
        placeholder="YYYY"
        value={year}
        readonly={readOnly}
        maxCharLenght={4}
       // disabled={readOnly}
      />
    </div>
  </>
}