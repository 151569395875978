import api from './api';

function validateCodeEntry(barcode, batchcode, retailer) {
    return api
        .get(`/entries/validate-codes/?barcode=${barcode}&batchcode=${batchcode}&retailer=${retailer}`);
}


async function validateAllCodesEntry(codes)
{
  var isValid = false;
 
  if(codes.currentCodeField > 0)
  {
    var results = await Promise.all([ validateCodeEntry(codes.barcode1, codes.batchcode1, codes.retailer1)]);
    
    isValid = (results && results[0] && results[0].isValid === true);
  }

  
//   console.log(isValid);
    return isValid;
}

export default {
    validateCodeEntry,
    validateAllCodesEntry
};