import ValidationErrorCollection from './ValidationErrorCollection';

const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const NAME_REGEX2 = /^(((\w|'|´|’|‘)+[a-zA-ZÀ-ÖØ-ŷ-öø-ÿ])|((\w|'|´|’|‘)+[a-zA-ZÀ-ÖØ-ŷ-öø-ÿ]+(\w|'|´|’|‘)))+( (((\w|'|´|’|‘)+[a-zA-ZÀ-ÖØ-ŷ-öø-ÿ])|((\w|'|´|’|‘)+[a-zA-ZÀ-ÖØ-ŷ-öø-ÿ]+(\w|'|´|’|‘))))*$/;
const NUMBERS_REGEX = /^(?:[+\d].*\d|\d)$/;

function validate(command, settings) {
  let messages = new ValidationErrorCollection();

  if (!command) {
    throw new Error('command cannot be null'); 
  }

  required('firstName', 'Please enter a first name');
  required('lastName', 'Please enter a last name');
  required('email', 'Please enter an email address');
  required('country', 'Please select a country');
  required('terms', 'Please confirm you agree to our terms & conditions');
  required('legalAge', 'Please confirm you are over 18');
  required('recaptcha2', 'Please confirm you are not a robot');

    
  if (command.firstName && !NAME_REGEX2.test(command.firstName)) {
    messages.addError('firstName', 'Please enter a valid first name');
  }

  if (command.lastName && !NAME_REGEX2.test(command.lastName)) {
    messages.addError('lastName', 'Please enter a valid last name');
  }

  if (command.email && !EMAIL_REGEX.test(command.email)) {
    messages.addError('email', 'Please enter a valid email address');
  }

  if (command.email && command.email.toLowerCase() !== command.emailConfirm.toLowerCase()) {
    messages.addError('emailConfirm', 'Please confirm your email');
  }
/*
  let mobileStrippedOfWhitespace = command.phoneNumber.replace(/\s+/g, '');

  if ((mobileStrippedOfWhitespace === '' || mobileStrippedOfWhitespace.split('').length > 13 || !NUMBERS_REGEX.test(mobileStrippedOfWhitespace))) {
    messages.addError('phoneNumber', 'Please enter a valid mobile number');
  }
*/
  if (command.dob && !command.dob.isValid) {
    messages.addError('dob', command.dob.error || "Please enter a valid date of birth");
  }

  //console.log(messages)

  return messages;
  
  function required(prop, error) {
    if (!command[prop]) {
      messages.addError(prop, error);
    }
  }
}

export default {
  validate
};
